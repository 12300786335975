






















































































































































































































































































































































































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import date from 'quasar/src/utils/date.js';;
import CCToggle from '../components/CCToggle.vue';
import PollutantBar from '../components/PollutantBar.vue';
import PollutantBarMobile from '../components/PollutantBarMobile.vue';
import { SocketIOMixin } from '../io-utils';
import { apiGet } from '../store';

@Component({
  components: {
    CCToggle,
    PollutantBar,
    PollutantBarMobile,
  },
})
export default class AirQualityArea extends Mixins(SocketIOMixin) {
  @Prop() public areaId!: number;

  public $store: any;

  public area: any = null;
  public buildingId: any;
  public areaLastData: any = {
    data: {},
  };
  public areaQai: number = 0;
  public loading: boolean = false;
  public toggleAllData: boolean = false;
  public building: any = null;
  public showTipId: number = 0;
  public showTipsDialog: boolean = false;
  public selectedTip: any = null;
  public selectedTipMessages: any = [];
  public mobileMode: boolean = false;
  public showMobileSituations: boolean = false;
  public mobileTips: any = [];
  public showMobileMessages: boolean = false;
  public mobileMessages: any = [];
  public isOnline: boolean = false;
  public lastUpdatedAt: any = null;

  private needRefresh: boolean = false;

  public created() {
    this.loading = true;
    this.mobileMode = this.$q.screen.lt.sm;
    this.refresh();
  }

  public refresh() {
    this.area = this.$store.getters.getAreaFromId(this.areaId);
    this.building = this.$store.getters.getBuildingFromAreaId(this.areaId);
    this.buildingId = this.building.id;
    this.loadAreaData();
  }

  public mounted() {
    const socket = this.ioListen('/qai-area');
    socket.once('connect', (message: any) => {
      socket.emit('area', this.areaId);
    })
    .on('indicators', (message: any) => {
      this.updateFromResponse(message.data);
    });
  }

  public beforeDestroy() {
    this.ioNamespace('/qai-area').off('indicators');
    this.ioClose();
  }

  @Watch('areaId')
  @Watch('buildingId')
  private onParamsChanged() {
    this.needRefresh = true;
    this.$nextTick().then(() => {
      if (this.needRefresh) {
        this.loading = true;
        this.refresh();
        this.needRefresh = false;
      }
    });
  }

  private loadAreaData() {
    apiGet(`/area/${this.areaId}/data/last`)
    .then((response: any) => {
      this.updateFromResponse(response);
      this.loading = false;
    })
    .catch((error: any) => {
      this.globalError(error);
    });
  }

  private updateFromResponse(response: any) {
      this.areaLastData = response;
      if (response.online === false || response.data.time === null) {
        this.isOnline = false;
        this.showTipsDialog = false;
        this.showMobileSituations = false;
        this.showMobileMessages = false;
        this.lastUpdatedAt = this.areaLastData.last_updated_at;
        this.areaLastData.data.qai = 3;
        this.areaQai = 3;
      } else {
        this.isOnline = true;
        this.areaQai = this.areaLastData.data.qai;
      }
  }

  private showTip(tip: any) {
    this.selectedTip = tip;
    this.selectedTipMessages = [];
    for (const message of this.selectedTip.messages) {
      this.selectedTipMessages.push(message);
    }
    this.showTipsDialog = true;
  }

  private showSituations(tip: any) {
    if (tip === undefined) {
      this.mobileTips = this.areaLastData.tips.tips;
    } else {
      this.mobileTips = [tip];
    }
    this.showMobileSituations = true;
  }

  private getAreasIds() {
    const areaIds: number[] = [];
    for (const level of this.building.levels) {
      for (const area of level.areas) {
        areaIds.push(area.id);
      }
    }
    return areaIds;
  }

  private modFix(v: number, n: number) {
    return ((v % n) + n) % n;
  }

  private goAreaNext(amount: number) {
    const areaIds: number[] = this.getAreasIds();
    const idx = areaIds.indexOf(this.areaId);
    const idxNext = this.modFix((idx + amount), areaIds.length);
    if (idx === idxNext) {
      return;
    }
    this.$router.push({
      name: 'airquality-area',
      params: {
        buildingId: String(this.buildingId),
        areaId: String(areaIds[idxNext]),
      },
    });
  }

  private showMessagesFor(tip: any) {
    const messages: any = [];
    for (const message of tip.messages) {
      if (messages.length === 2) { break; }
      messages.push(message);
    }
    this.mobileMessages = messages;
    this.showMobileMessages = true;
  }

  private hideMessage() {
    this.showMobileMessages = false;
  }

  get backlink() {
    if (this.$route.name === 'airquality-area-graph') {
      if (this.$q.screen.lt.md) {
        return {
          name: 'airquality-area',
          params: {
            id: this.area.id,
          },
        };
      }
    }
    return {
      name: 'airquality-building',
      params: {
        id: this.building.id,
      },
    };
  }

}
